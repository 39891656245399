<template src="./Events.html"></template>

<script>
const { DateTime } = require("luxon");
import createEditEvent from "@/components/Events/createEditEvent";
import showEventDetails from "@/components/Events/showEventDetails";
import ArrobaMedellinSeeker from "@/components/ArrobaMedellinSeeker/ArrobaMedellinSeeker";
import ArrobaMedellinArrobitaNoData from "@/components/ArrobaMedellinArrobitaNoData/ArrobaMedellinArrobitaNoData";
import ArrobaMedellinNoData from "../../components/ArrobaMedellinNoData/ArrobaMedellinNoData.vue";
import ArrobaMedellinHorizontalCard from "@/components/ArrobaMedellinHorizontalCard/ArrobaMedellinHorizontalCard";
import ArrobaMedellinVerticalCardEvents from "@/components/ArrobaMedellinVerticalCardEvents/ArrobaMedellinVerticalCardEvents";
import { mapGetters } from "vuex";
import eventsService from "@/services/Events";
import { roleName } from "@/utils/constants";
import tutorial from "@/mixins/tutorial";
import likeCommentService from "../../services/LikeAndComment";
import notificationsService from "../../services/Notifications";

export default {
  name: "Events",
  mixins: [tutorial],
  components: {
    ArrobaMedellinSeeker,
    ArrobaMedellinArrobitaNoData,
    ArrobaMedellinVerticalCardEvents,
    ArrobaMedellinHorizontalCard,
    createEditEvent,
    showEventDetails,
    ArrobaMedellinNoData,
  },
  data() {
    return {
      itemsToShowCreatedEvents: 1,
      itemsToShow: 1,
      eventsToShow: [],
      errorMessage: false,
      isComponentModalActive: false,
      dataModalComponent: {},
      modalComponent: "",
      typeCard: { button: "button--green", size: "small" },
      todaysEvents: [],
      lastEvents: [],
      itemsToShowLastEvents: 1,
      role: null,
      roleName,
      modalShare: false,
      rating: {
        qualification: "",
        comment: "",
      },
      percent: {
        progress: 0,
        progress2: 100,
      },
      infoShare: {},
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      qualification: "getModalQualification",
      watchAndRate: "getModalWatchAndRate",
      shareAndLoginEvent: "getModalShareAndLoginEvent",
    }),
    roleValidationNoAccess() {
      return ["AdministratorCall"].some((a) => a === this.role);
    },
  },
  filters: {
    dateFormat: function (value) {
      return DateTime.fromISO(value).toFormat("dd LLLL yyyy / HH:mm", {
        locale: "es",
      });
    },
  },
  methods: {
    showModalQualification(data) {
      this.$store.dispatch("fetchQualification", {
        show: true,
        data: data,
      });
      this.$store.dispatch("fetchWatchAndRate", false);
    },
    closeModalQualification() {
      this.$store.dispatch("fetchQualification", false);
    },
    reportWindowSize() {
      if (window.innerWidth < 550) {
        this.itemsToShowCreatedEvents = Number(1);
        this.itemsToShowLastEvents = Number(1);
      } else if (window.innerWidth < 750) {
        this.itemsToShowCreatedEvents = Number(2);
        this.itemsToShowLastEvents = Number(2);
      } else if (window.innerWidth < 1224) {
        this.itemsToShowCreatedEvents = Number(3);
        this.itemsToShowLastEvents = Number(1);
      } else if (window.innerWidth < 1580) {
        this.itemsToShowCreatedEvents = Number(4);
        this.itemsToShowLastEvents = Number(2);
        this.itemsToShowSliderBottom = Number(3);
      } else if (window.innerWidth > 1580) {
        this.itemsToShowCreatedEvents = Number(5);
        this.itemsToShowLastEvents = Number(2);
      }
    },
    createEvent() {
      this.isComponentModalActive = true;
      this.dataModalComponent = {};
      this.modalComponent = "createEditEvent";
    },
    editEvent(eventData) {
      if (this.role === this.roleName.ADMINISTRATOR) {
        this.isComponentModalActive = true;
        this.dataModalComponent = { ...eventData };
        this.modalComponent = "createEditEvent";
      }
    },
    async deleteEvent(eventData) {
      this.$buefy.dialog.confirm({
        title: "Eliminar evento",
        type: "is-green",
        message: "¿Estás seguro que quieres eliminar éste evento?",
        confirmText: "Eliminar evento",
        cancelText: "Cancelar",
        onConfirm: async () => {
          try {
            await eventsService.deleteEvent(eventData.id);
            await this.getMyCreatedEvents();
            this.$buefy.toast.open({
              duration: 5000,
              message: "Evento eliminado exitosamente",
              position: "is-bottom",
              type: "is-success",
            });
          } catch (e) {
            this.$buefy.toast.open({
              duration: 5000,
              message: e.response.data.message,
              position: "is-bottom",
              type: "is-danger",
            });
          }
        },
      });
    },
    async getMyCreatedEvents() {
      try {
        this.eventsToShow = await eventsService.getMyCreatedEvents();
      } catch (e) {
        console.error("error", e);
      }
    },
    async getLastEvents() {
      try {
        this.lastEvents = await eventsService.getLastEvents();
      } catch (e) {
        console.error("error", e);
      }
    },
    async getTodaysEvents() {
      try {
        this.todaysEvents = await eventsService.getTodaysEvents();
      } catch (e) {
        console.error("error", e);
      }
    },
    async getRecommendedEvents() {
      try {
        this.eventsToShow = await eventsService.getRecommendedEvents();
      } catch (e) {
        console.error("error", e);
      }
    },
    showEventDetails(data) {
      if (this.role === this.roleName.ADMINISTRATOR) {
        this.isComponentModalActive = true;
        this.dataModalComponent = { ...data };
        this.modalComponent = "showEventDetails";
      }
    },
    async getEventSearch(word) {
      this.errorMessage = false;
      try {
        if (!word) {
          this.eventsToShow = await eventsService.getMyCreatedEvents();
        } else {
          this.eventsToShow = await eventsService.getSearchEvents(word);
          this.eventsToShow = this.eventsToShow.splice(0, 4);
          if (this.eventsToShow.length === 0) this.errorMessage = true;
          else this.errorMessage = false;
        }
      } catch (e) {
        console.error("error", e);
      }
    },
    reload() {
      if (this.role === this.roleName.ADMINISTRATOR) {
        this.getMyCreatedEvents();
      } else {
        this.getLastEvents();
        this.getTodaysEvents();
        this.getRecommendedEvents();
      }
    },
    viewTutorial() {
      this.dataTutorial("events");
    },
    async commentsRating(id) {
      try {
        const obj = {
          post: id,
          typePost: "event",
          starsNumber: Number(this.rating.qualification),
          comment: this.rating.comment,
        };
        await likeCommentService.commentAndRate(obj);
        this.rating = {};
        this.$store.dispatch("fetchQualification", {
          show: false,
          data: "",
        });
        this.$buefy.toast.open({
          duration: 5000,
          message: "Calificación recibida",
          position: "is-bottom",
          type: "is-success",
        });
      } catch (error) {
        this.$buefy.toast.open({
          duration: 5000,
          message: error.response.data.message,
          position: "is-bottom",
          type: "is-green",
        });
        this.rating = {};
        this.$store.dispatch("fetchQualification", {
          show: false,
          data: "",
        });
        console.error("Error grading the course", error);
      }
    },
    async shareEvent() {
      try {
        const infoShare = {
          title: "Te han compartido un evento",
          body: this.infoShare.name,
          // TO DO servicio mal estructurado no deberia enviarse el id de la notificacion quemado
          typeId: "407b6e17-f42c-443e-b1fe-bffe085f31b6",
        };
        await notificationsService.shareAnnouncement(infoShare);
        this.modalShare = false;
        this.$buefy.toast.open({
          duration: 5000,
          message: "Evento compartido",
          position: "is-bottom",
          type: "is-success",
        });
      } catch (error) {
        console.error("Error grading the course", error);
      }
    },
    watchVideo(video) {
      window.open(video.data.videoUrl, "_blank").focus();
    },
    closeModalWatchAndRate() {
      this.$store.dispatch("fetchWatchAndRate", false);
    },
    closeModalShareAndLoginEvent() {
      this.$store.dispatch("fetchShareAndLoginEvent", false);
    },
    loginEvent(dataEvent) {
      this.$router.push({
        name: "Event",
        params: { idEvent: dataEvent.id },
      });
      this.closeModalShareAndLoginEvent();
    },
    showModalShareEvent(dataEvent) {
      this.infoShare = dataEvent;
      this.modalShare = true;
      this.closeModalShareAndLoginEvent();
    },
  },
  watch: {
    isComponentModalActive() {
      this.reload();
    },
  },
  created() {
    this.role = this.user.role.name;
    this.reload();
  },
  mounted() {
    window.addEventListener("resize", this.reportWindowSize);
    this.reportWindowSize();
    this.viewTutorial();
  },
};
</script>

<style scoped lang="scss" src="./Events.scss">
</style>
